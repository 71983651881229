import { BrowserRouter as Router, Route, Redirect, withRouter } from 'react-router-dom';
import React, { Suspense, lazy, Fragment } from 'react';
import Loader from 'react-loaders'
import { connect, useDispatch, useSelector } from "react-redux";

import PrivateRoute from '../../common/PrivateRoute'
import Error404 from '../../pages/Auth/Error404'

import {
  ToastContainer,
} from 'react-toastify';
import { consts } from '../../assets/values/constants';

const AuthPages = lazy(() => import('../../pages/Auth'));
const CustomerPages = lazy(() => import('../../pages/Customers'));
const OrderPages = lazy(() => import('../../pages/Orders'));
const DashboardPages = lazy(() => import('../../pages/Dashboard'));
const FinancePages = lazy(() => import('../../pages/Finance'));
const ReportPages = lazy(() => import('../../pages/Reports'));

const AppMain = ({ auth }) => {
  // if (!auth.user) {
  //     return <Fragment />;
  // }

  return (
    <Fragment>

      {/* Users */}
      <Suspense fallback={
        <div className="loader-container">
          <div className="loader-container-inner">
            <div className="text-center">
              <Loader type="ball-pulse-rise" />
            </div>
          </div>
        </div>
      }>
        <PrivateRoute exact path="/">
          <Redirect to="/dashboard" />
        </PrivateRoute>
        <PrivateRoute path="/customers" component={CustomerPages} />
        <PrivateRoute path="/orders" component={OrderPages} />
        <PrivateRoute path="/dashboard" component={DashboardPages} />
        <PrivateRoute path="/finance" component={FinancePages} />
        <PrivateRoute path="/reports" component={ReportPages} />
        <Route path="/auth" component={AuthPages} />
        <Route path="/error" component={Error404} />
      </Suspense>
      <ToastContainer />
    </Fragment>
  )
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(withRouter(AppMain));
