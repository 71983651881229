export const consts = {
  GOOGLE_API_KEY: 'AIzaSyAfTlWss4rCm5xcHXcHqjr86rEF0yoBMmc',
  FDL_TIMEZONE: "Europe/London"
}

export const COLORS = {
  primary: '#32006E',
  secondary: '#FCB816',
  danger: '#DF382C',
  warning: '#EFB73E',
  success: '#38B44A',
  gray: '#495057',
  darkGray: '#333333',
  lightGray: '#868e96',
  white: '#ffffff',
  blue: '#007bff'
}

export const UserRoleValues = {
  SUPER_ADMIN: 'super_admin',
  ADMIN: 'admin',
  TRANSPORT_ADMIN: 'transport_admin',
  HR_MANAGER: 'hr_manager',
  PAYMENT_CLERK: 'payment_clerk',
  REGION_MANAGER: 'region_manager',
  DEPOT_MANAGER: 'depot_manager',
  CALL_CENTER_ASSISTANT: 'call_center_assistant',
  PARTNER: 'partner',
  SUPPLIER_ADMIN: 'supplier_admin',
  SALES_REP: 'sales_rep',
  DRIVER: 'driver',
}

export const UserRoleTitles = {
  [UserRoleValues.SUPER_ADMIN]: 'Super Admin',
  [UserRoleValues.ADMIN]: 'Admin',
  [UserRoleValues.TRANSPORT_ADMIN]: 'Transport Admin',
  [UserRoleValues.HR_MANAGER]: 'HR',
  [UserRoleValues.PAYMENT_CLERK]: 'Payment Clerk',
  [UserRoleValues.REGION_MANAGER]: 'Region Manager',
  [UserRoleValues.DEPOT_MANAGER]: 'Depot Manager',
  [UserRoleValues.CALL_CENTER_ASSISTANT]: 'Call Centre Assitant',
  [UserRoleValues.PARTNER]: 'Partner',
  [UserRoleValues.SUPPLIER_ADMIN]: 'Supplier Admin',
  [UserRoleValues.SALES_REP]: 'Sales',
  [UserRoleValues.DRIVER]: 'Driver',
}

export const GenderValues = {
  MALE: 'male',
  FEMALE: 'female',
}

export const GenderTitles = {
  [GenderValues.MALE]: 'Male',
  [GenderValues.FEMALE]: 'Female',
}


// ---------------------- Order Values --------------------------

// ---------------------- Order Values --------------------------

export const OrderStatusValues = {
  CREATED: 'created',

  ROUTE_ASSIGNED_FOR_COLLECTION: 'route_assigned_for_collection',
  DRIVER_ASSIGNED_FOR_COLLECTION: 'driver_assigned_for_collection',
  OUT_FOR_COLLECTION: 'out_for_collection',
  COLLECTED: 'collected',
  NOT_COLLECTED: 'not_collected',

  RECEIVED_AT_COLLECTION_DEPOT: 'received_at_collection_depot',
  IN_TRANSIT_TO_SORTATION_DEPOT: 'in_transit_to_sortation_depot',
  RECEIVED_AT_SORTATION_DEPOT: 'received_at_sortation_depot',
  IN_TRANSIT_TO_DELIVERY_DEPOT: 'in_transit_to_delivery_depot',
  RECEIVED_AT_LAST_DEPOT: 'received_at_last_depot',

  ROUTE_ASSIGNED_FOR_DELIVERY: 'route_assigned_for_delivery',
  DRIVER_ASSIGNED_FOR_DELIVERY: 'driver_assigned_for_delivery',
  // AWAITING_PICKUP_FOR_DELIVERY: 'awaiting_pickup_for_delivery',
  OUT_FOR_DELIVERY: 'out_for_delivery',
  DELIVERED: 'delivered',
  PARTIALLY_DELIVERED: 'partially_delivered',
  NOT_DELIVERED: 'not_delivered',
  INVOICED: 'invoiced',
  FINISHED: 'finished',

  MISSING: 'missing',
  DELAYED: 'delayed',
  DAMAGED_IN_TRANSIT: 'damaged_in_transit',
  LOST: 'lost',

  SENT_TO_SENDER: 'sent_to_sender',
  RETURN_FINISHED: 'return_finished'
}

export const OrderStatusTitles = {
  [OrderStatusValues.CREATED]: 'Created',

  [OrderStatusValues.ROUTE_ASSIGNED_FOR_COLLECTION]: 'Route Assigned for Collection',
  [OrderStatusValues.DRIVER_ASSIGNED_FOR_COLLECTION]: 'Driver Assigned for Collection',
  [OrderStatusValues.OUT_FOR_COLLECTION]: 'Out for Collection',
  [OrderStatusValues.COLLECTED]: 'Collected',
  [OrderStatusValues.NOT_COLLECTED]: 'Not Collected',

  [OrderStatusValues.RECEIVED_AT_COLLECTION_DEPOT]: 'Received at Collection Depot',
  [OrderStatusValues.IN_TRANSIT_TO_SORTATION_DEPOT]: 'In Transit to Sortation Depot',
  [OrderStatusValues.RECEIVED_AT_SORTATION_DEPOT]: 'Received at Sortation Depot',
  [OrderStatusValues.IN_TRANSIT_TO_DELIVERY_DEPOT]: 'In Transit to Delivery Depot',
  [OrderStatusValues.RECEIVED_AT_LAST_DEPOT]: 'Received at Delivery Depot',

  [OrderStatusValues.ROUTE_ASSIGNED_FOR_DELIVERY]: 'Route Assigned for Delivery',
  [OrderStatusValues.DRIVER_ASSIGNED_FOR_DELIVERY]: 'Awaiting Pickup for Delivery',
  // [OrderStatusValues.AWAITING_PICKUP_FOR_DELIVERY]: 'Awaiting Pickup for Delivery',
  [OrderStatusValues.OUT_FOR_DELIVERY]: 'Out for Delivery',
  [OrderStatusValues.DELIVERED]: 'Delivered',
  [OrderStatusValues.PARTIALLY_DELIVERED]: 'Partially Delivered',
  [OrderStatusValues.NOT_DELIVERED]: 'Not Delivered',
  [OrderStatusValues.INVOICED]: 'Invoiced',
  [OrderStatusValues.FINISHED]: 'Finished',

  [OrderStatusValues.MISSING]: 'Delivery Delayed',
  [OrderStatusValues.DELAYED]: 'Delivery Delayed',
  [OrderStatusValues.DAMAGED_IN_TRANSIT]: 'Damaged in Transit',
  [OrderStatusValues.LOST]: 'Delivery Delayed',

  [OrderStatusValues.SENT_TO_SENDER]: 'Sent to Sender',
  [OrderStatusValues.RETURN_FINISHED]: 'Return Finished',
}

export const OrderStatusColors = {
  [OrderStatusValues.CREATED]: COLORS.gray,

  [OrderStatusValues.ROUTE_ASSIGNED_FOR_COLLECTION]: COLORS.primary,
  [OrderStatusValues.DRIVER_ASSIGNED_FOR_COLLECTION]: COLORS.primary,
  [OrderStatusValues.OUT_FOR_COLLECTION]: COLORS.warning,
  [OrderStatusValues.COLLECTED]: COLORS.success,
  [OrderStatusValues.NOT_COLLECTED]: COLORS.danger,

  [OrderStatusValues.RECEIVED_AT_COLLECTION_DEPOT]: COLORS.primary,
  [OrderStatusValues.IN_TRANSIT_TO_SORTATION_DEPOT]: COLORS.gray,
  [OrderStatusValues.RECEIVED_AT_SORTATION_DEPOT]: COLORS.primary,
  [OrderStatusValues.IN_TRANSIT_TO_DELIVERY_DEPOT]: COLORS.gray,
  [OrderStatusValues.RECEIVED_AT_LAST_DEPOT]: COLORS.primary,

  [OrderStatusValues.ROUTE_ASSIGNED_FOR_DELIVERY]: COLORS.primary,
  [OrderStatusValues.DRIVER_ASSIGNED_FOR_DELIVERY]: COLORS.warning,
  // [OrderStatusValues.AWAITING_PICKUP_FOR_DELIVERY]: COLORS.warning,
  [OrderStatusValues.OUT_FOR_DELIVERY]: COLORS.blue,
  [OrderStatusValues.DELIVERED]: COLORS.success,
  [OrderStatusValues.PARTIALLY_DELIVERED]: COLORS.warning,
  [OrderStatusValues.NOT_DELIVERED]: COLORS.danger,
  [OrderStatusValues.INVOICED]: COLORS.success,
  [OrderStatusValues.FINISHED]: '#bbbbbb',

  [OrderStatusValues.MISSING]: COLORS.lightGray,
  [OrderStatusValues.DELAYED]: COLORS.lightGray,
  [OrderStatusValues.DAMAGED_IN_TRANSIT]: COLORS.danger,
  [OrderStatusValues.LOST]: COLORS.lightGray,

  [OrderStatusValues.SENT_TO_SENDER]: COLORS.lightGray,
  [OrderStatusValues.RETURN_FINISHED]: COLORS.success
}

export const OrderTypeValues = {
  DELIVERY: 'delivery',
  RETURN: 'return',
}

export const OrderTypeTitles = {
  [OrderTypeValues.DELIVERY]: 'Delivery',
  [OrderTypeValues.RETURN]: 'Return',
}


// ---------------------- Recipient Values --------------------------
export const RecipientTypeValues = {
  BUSINESS: 'business',
  RESIDENTIAL: 'residential',
}

export const RecipientTypeTitles = {
  [RecipientTypeValues.BUSINESS]: 'Business',
  [RecipientTypeValues.RESIDENTIAL]: 'Residential',
}

export const RecipientStatusValues = {
  ACTIVE: 'active',
  INACITVE: 'inactive',
  DELETED: 'deleted'
}

export const RecipientStatusTitles = {
  [RecipientStatusValues.ACTIVE]: 'Active',
  [RecipientStatusValues.INACITVE]: 'Inactive',
  [RecipientStatusValues.DELETED]: 'Deleted'
}

// ---------------------- Cod Method Values --------------------------

export const CODMethodValues = {
  CASH: 'cash',
  BANK_TRANSFER: 'bank_transfer',
  FDL_CREDIT: 'fdl_credit'
}

export const CODMethodTitles = {
  [CODMethodValues.CASH]: 'Cash',
  [CODMethodValues.BANK_TRANSFER]: 'Bank Transfer',
  [CODMethodValues.FDL_CREDIT]: 'Credit',
}

// Parcel Status Constants

export const ParcelStatusValues = {
  CREATED: 'created',
  RECEIVED_BY_DRIVER: 'received_by_driver',
  RECEIVED_AT_SORTATION_DEPOT: 'received_at_sortation_depot',
  IN_TRANSIT_TO_DELIVERY_DEPOT: 'in_transit_to_delivery_depot',
  IN_TRANSIT_TO_SORTATION_DEPOT_ND: 'in_transit_to_sortation_depot_nd',
  RECEIVED_AT_DEPOT: 'received_at_depot',
  IN_DELIVERY: 'in_delivery',
  DELIVERED: 'delivered',
  NOT_DELIVERED: 'not_delivered',
  ACCEPTED: 'accepted',
  RETURNED: 'returned',
  SENT_TO_SENDER: 'sent_to_sender',
  SENT_TO_NEW_ORDER: 'sent_to_new_order',
  KEEP_AT_DEPOT: 'keep_at_depot',
}

export const ParcelStatusTitles = {
  [ParcelStatusValues.CREATED]: 'Created',
  [ParcelStatusValues.RECEIVED_BY_DRIVER]: 'Received by Driver',
  [ParcelStatusValues.RECEIVED_AT_SORTATION_DEPOT]: 'Received at Sortation Depot',
  [ParcelStatusValues.IN_TRANSIT_TO_DELIVERY_DEPOT]: 'In Transit to Delivery Depot',
  [ParcelStatusValues.IN_TRANSIT_TO_SORTATION_DEPOT_ND]: 'In Transit to Sortation Depot - ND',
  [ParcelStatusValues.RECEIVED_AT_DEPOT]: 'Received at Delivery Depot',
  [ParcelStatusValues.IN_DELIVERY]: 'In Delivery',
  [ParcelStatusValues.DELIVERED]: 'Delivered',
  [ParcelStatusValues.NOT_DELIVERED]: 'Not Delivered',
  [ParcelStatusValues.ACCEPTED]: 'Accepted',
  [ParcelStatusValues.RETURNED]: 'Returned',
  [ParcelStatusValues.SENT_TO_SENDER]: 'Sent to Sender',
  [ParcelStatusValues.SENT_TO_NEW_ORDER]: 'Sent to New Order',
  [ParcelStatusValues.KEEP_AT_DEPOT]: 'keep_at_depot',
}

export const ParcelStatusColors = {
  [ParcelStatusValues.CREATED]: COLORS.gray,
  [ParcelStatusValues.RECEIVED_BY_DRIVER]: COLORS.primary,
  [ParcelStatusValues.RECEIVED_AT_SORTATION_DEPOT]: COLORS.primary,
  [ParcelStatusValues.IN_TRANSIT_TO_DELIVERY_DEPOT]: COLORS.warning,
  [ParcelStatusValues.RECEIVED_AT_DEPOT]: COLORS.primary,
  [ParcelStatusValues.IN_DELIVERY]: COLORS.warning,
  [ParcelStatusValues.DELIVERED]: COLORS.success,
  [ParcelStatusValues.NOT_DELIVERED]: COLORS.danger,
  [ParcelStatusValues.ACCEPTED]: COLORS.success,
  [ParcelStatusValues.RETURNED]: COLORS.danger,
  [ParcelStatusValues.SENT_TO_SENDER]: COLORS.gray,
  [ParcelStatusValues.SENT_TO_NEW_ORDER]: COLORS.primary,
  [ParcelStatusValues.KEEP_AT_DEPOT]: COLORS.gray,
}


// ------------------------ Support Values ------------------------

export const TicketRelationValues = {
  ORDER: 0,
  RETURN: 1,
  LABEL: 2,
  FINANCE: 10,
  TECHNICAL: 20
}

export const TicketRelationTitles = {
  [TicketRelationValues.ORDER]: 'Order',
  [TicketRelationValues.RETURN]: 'Return',
  [TicketRelationValues.LABEL]: 'Labels',
  [TicketRelationValues.FINANCE]: 'Financial Issue',
  [TicketRelationValues.TECHNICAL]: 'Technical Issue',
}

export const TicketPriorityValues = {
  LOW: 0,
  MEDIUM: 1,
  HIGH: 2,
}

export const TicketPriorityTitles = {
  [TicketPriorityValues.LOW]: 'Low',
  [TicketPriorityValues.MEDIUM]: 'Medium',
  [TicketPriorityValues.HIGH]: 'High',
}

export const TicketStatusValues = {
  CREATED: 0,
  IN_PROGRESS: 1,
  FINISHED: 10,
  CANCELLED: 11
}

export const TicketStatusTitles = {
  [TicketStatusValues.CREATED]: 'Created',
  [TicketStatusValues.IN_PROGRESS]: 'In Progress',
  [TicketStatusValues.FINISHED]: 'Finished',
  [TicketStatusValues.CANCELLED]: 'Cancelled',
}


// -------------------- Shipment Leave Place -----------------

export const LeaveTypeValues = {
  CUSTOMER: 'customer',
  // For Business
  MAIL_ROOM: 'mail_room',
  RECEPTION: 'reception',
  SHOP_ASSISTANT: 'shop_assistant',
  // For Residential
  NEIGHBOR: 'neighbor',
  FRONT_PORCH: 'front_porch',
  REAR_PORCH: 'rear_porch',
  GARDEN: 'garden',
  BEHIND_WHEELIE_BIN: 'behind_wheelie_bin',
  SHED_GARDEN_HOUSE: 'shed_garden_house',
  LETTERBOX: 'letterbox',
}

export const LeaveTypeTitles = {
  [LeaveTypeValues.CUSTOMER]: 'Customer',
  // For Business
  [LeaveTypeValues.MAIL_ROOM]: 'Mail Room',
  [LeaveTypeValues.RECEPTION]: 'Reception',
  [LeaveTypeValues.SHOP_ASSISTANT]: 'Shop Assistant',
  // For Residential
  [LeaveTypeValues.NEIGHBOR]: 'Neighbour',
  [LeaveTypeValues.FRONT_PORCH]: 'Front Porch',
  [LeaveTypeValues.REAR_PORCH]: 'Rear Porch',
  [LeaveTypeValues.GARDEN]: 'Rear Garden',
  [LeaveTypeValues.BEHIND_WHEELIE_BIN]: 'Behind Wheelie Bin',
  [LeaveTypeValues.SHED_GARDEN_HOUSE]: 'Shed Garden House',
  [LeaveTypeValues.LETTERBOX]: 'Letter Box',
}

// -------------------- Invoice  -----------------

export const InvoiceStatusValues = {
  CREATED: 'created',
  APPROVED_BY_MANAGER: 'approved_by_manager',
  SENT_TO_CUSTOMER: 'sent_to_customer',
  APPROVED_BY_CUSTOMER: 'approved_by_customer',
  DISPUTED_BY_CUSTOMER: 'disputed_by_customer',
  PAID: 'paid',
  PAYMENT_CONFIRMED: 'payment_confirmed',
}

export const InvoiceStatusTitles = {
  [InvoiceStatusValues.CREATED]: 'CREATED',
  [InvoiceStatusValues.APPROVED_BY_MANAGER]: 'APPROVED BY MANAGER',
  [InvoiceStatusValues.SENT_TO_CUSTOMER]: 'AWAITING APPROVAL',
  [InvoiceStatusValues.APPROVED_BY_CUSTOMER]: 'APPROVED',
  [InvoiceStatusValues.DISPUTED_BY_CUSTOMER]: 'DISPUTE SENT MANAGER',
  [InvoiceStatusValues.PAID]: 'AWAITING APPROVAL OF PAYMENT',
  [InvoiceStatusValues.PAYMENT_CONFIRMED]: 'PAYMENT CONFIRMED',
}

export const InvoiceStatusColors = {
  [InvoiceStatusValues.CREATED]: COLORS.gray,
  [InvoiceStatusValues.APPROVED_BY_MANAGER]: COLORS.blue,
  [InvoiceStatusValues.SENT_TO_CUSTOMER]: COLORS.gray,
  [InvoiceStatusValues.APPROVED_BY_CUSTOMER]: COLORS.primary,
  [InvoiceStatusValues.DISPUTED_BY_CUSTOMER]: COLORS.danger,
  [InvoiceStatusValues.PAID]: COLORS.warning,
  [InvoiceStatusValues.PAYMENT_CONFIRMED]: COLORS.success,
}

export const ParcelPriceRangeTypeValues = {
  NONE: 'none',
  PRICE_RANGE: 'price_range',
  SURCHARGE: 'surcharge',
  EXCLUDED: 'excluded',
  CUSTOM: 'custom',
  ERROR: 'error'
}

// -------------------- Credit Notes  -----------------

export const CreditNoteStatusValues = {
  CREATED: 'created',
  APPROVED_BY_MANAGER: 'approved_by_manager',
}

export const CreditNoteStatusTitles = {
  [CreditNoteStatusValues.CREATED]: 'CREATED',
  [CreditNoteStatusValues.APPROVED_BY_MANAGER]: 'APPROVED BY MANAGER',
}