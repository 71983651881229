import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';

import ResizeDetector from 'react-resize-detector';
import { loadUser } from '../../actions/auth'


import AppMain from '../../layout/AppMain';
import { setScreenWidth } from '../../reducers/ThemeOptions';

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closedSmallerSidebar: false
    };
  }

  componentDidMount() {

  }

  render() {
    let {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
      setScreenWidth,
    } = this.props;

    return (
      <ResizeDetector
        handleWidth
        render={({ width }) => {
          setScreenWidth(width);
          return <Fragment>
            <div className={cx(
              "app-container app-theme-" + colorScheme,
              { 'fixed-header': true },
              { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
              { 'fixed-footer': enableFixedFooter },
              { 'closed-sidebar': enableClosedSidebar || width < 1250 },
              { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
              { 'sidebar-mobile-open': enableMobileMenu },
              { 'body-tabs-shadow-btn': enablePageTabsAlt },
            )}>
              <AppMain />
            </div>
          </Fragment>
        }}
      />
    )
  }
}

const mapStateToProp = state => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

const mapDispatchToProps = dispatch => ({
  setScreenWidth: enable => dispatch(setScreenWidth(enable)),
});

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(Main));