import React, { Component } from "react"
import { Route, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import Loader from 'react-loaders';
import PropTypes from "prop-types"

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (auth.isLoading) {
                return <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                </div>;
            } else if (!auth.isAuthenticated) {
                return <Redirect to="/auth/login" />
            } else {
                return <Component {...props} />
            }
        }}
    />
)

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps)(PrivateRoute)